<template>
	<div>
		<b-tabs>
			
			
			<b-tab active  v-if="permision_access[20].vb_view==1">
				<template #title>
					<strong>Entries</strong>  
				</template>
				<expense_entry/>
			</b-tab> 
			
			
			
		</b-tabs>
	</div>
</template>

<script>
	import axios from "axios";
import { mapState } from "vuex";
	import expense_entry from './expense_entry'

	export default {
		
		data:() => ({
		permision_access:'',
	}),
		components:
		{
			expense_entry,
			

		},
		methods:
  {
	access_permission()
	{
		axios
			.post("/api/access_permission_view").then((resp) => {
				this.permision_access = resp.data;
			});
	}
  },
  mounted()
  {
	this.access_permission();
  },
	computed: {
    ...mapState(['set_income_expense_option']),
   },
  }
  
</script>

<style>
	.input__autocomplete input:first-child
	{
		border:none !important;
	}
</style>