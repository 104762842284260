<template>
    <div>
      <b-row>
			<b-col lg="6" md="6" sm="6" class="heading-title">
				<h4>Income/Expense</h4>
				<b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
					<b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
					<b-breadcrumb-item active>Income/Expense </b-breadcrumb-item>
				</b-breadcrumb>
			</b-col>
			<b-col lg="6" md="6" sm="6" class="btns-header"> 
				
			</b-col>
		</b-row>
		<br>
    <b-tabs class="finance-custom-navbar">
      <!-- <b-tab active >
        <template #title>
          <strong>Booking</strong>
        </template>
      <Booking />
      </b-tab>
   -->
      <b-tab :active="set_income_option=='income'" v-if="permision_access[13].vb_view==1">
        <template #title >
            <strong>Income</strong>  
        </template>
       <Income/>
      </b-tab> 
      <b-tab v-if="permision_access[14].vb_view==1">
        <template #title>
            <strong>Expense</strong>   
        </template>
        
        <Expense/>
      </b-tab>
  
    </b-tabs>
  </div>
  </template>
  
  <script>
  import axios from "axios";
  import { mapState } from "vuex";
 //  import Booking from '../finance/Booking/booking.vue'
   //import Income from './finance/Income/income.vue'
   import Expense from './Expense/expense'
   import Income from './Income/income'
  export default {
    data:() => ({
		permision_access:'',
	}),
  components:
  {
    Income,
    //Booking,
    Expense,
   // Tax,
  },
  methods:
  {
	access_permission()
	{
		axios
			.post("/api/access_permission_view").then((resp) => {
				this.permision_access = resp.data;
			});
	}
  },
  mounted()
  {
	this.access_permission();
  },
  computed: {
    ...mapState(['set_income_option']),
   },
  }
  </script>
  
  <style>
  .finance-custom-navbar .nav-link.active  {
        color: white !important;
        background-color: #499CE8 !important;
        border-bottom: 2px solid #DD1BFB !important;
        border-color: 0 !important;
        }
  </style>