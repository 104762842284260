<template>
	<div>

        <!-- Modal -->
        <b-modal id="open_ledger_list" 
					title="Add New Ledger" 
					ok-title="Save" 
					header-bg-variant="primary" 
					header-text-variant="light" 
					ref="open_ledger_list"
					:no-close-on-backdrop="false"
					hide-footer
					@ok="handleOk"
                    centered
					>
						
						<div>
							<b-form-group
							label="Category"
							label-for="popover-input-2"
							label-cols="4"
							:state="input2state"
							class="mb-1"
							>
								<b-form-select
								id="popover-input-2"
								v-model="input2"
								:state="input2state"
								:options="options_"
								size="sm"
								></b-form-select>
							</b-form-group>
							<b-form-group
							label="Name"
							label-for="popover-input450"
							label-cols="4"
							class="mb-1"
							description="Enter  name"
							invalid-feedback="This field is required"
							>
								<b-form-input
								type="text"
								id="popover-input-2"
								v-model="ledger_cat"
								:state="input2state"
								size="sm"
								></b-form-input>
							</b-form-group>
							
							
							
							
							
							
							<b-button  size="sm" variant="danger" class="ml-2 mb-2 float-right">Cancel</b-button>
							<b-button @click="onOk" size="sm" variant="primary" class="ml-2 mb-2 float-right">Save</b-button>
						</div>
					</b-modal>
					
					<!-- Ledger Modal  -->
					<b-modal id="modalPopover"
					:title="ty+' Income Entries'"
					ok-title="Save" 
					header-bg-variant="primary" 
					header-text-variant="light" 
					ref="open_booking_list"
					:no-close-on-backdrop="false"
					@ok="handleOk"
                    centered
					>
						<div class="row">
							<div class="col-md-6 fw-bold text-danger">
								<label><br></label>
								Receipt No : #{{ receipt_no }}
							</div>
							
							<div class="col-md-6">
								
								<b-form-input
								name="date"
								:state="validateState('date')"
								v-model="date"
								type="date"
								aria-describedby="contact-1-live-feedback"
								>
								</b-form-input>
								<b-form-invalid-feedback id="contact-1-live-feedback">
									<span v-if="!this.$v.date.required">
										Please Select Date.</span
									>
									
								</b-form-invalid-feedback>
							</div>
						</div>
						
						
						<!-- Popover  -->
						
						<!-- Popover  -->
						
						
						
						
						<div class="d-flex mt-1 mb-1">
							<b-form-radio name="radio-size" v-model="paymode" value="Cash">Cash</b-form-radio> &nbsp; &nbsp; &nbsp;
							<b-form-radio name="radio-size" v-model="paymode" value="Bank">Bank</b-form-radio> &nbsp; &nbsp; &nbsp;
							
							<div v-if="paymode=='Bank'">
								<b-form-select
								id="popover-input-2"
								v-model="input5"
								:options="banks"
								size="sm"
								></b-form-select>
							</div>
						</div>
						<div class="row" v-for="(input,k) in inputs" :key="k">
							<div class="col-md-6 fw-bold mt-3">
								<label>Ledger</label>
								<typeahead-autocomplete class="form-control"
								v-model="input.name"
								:items="select_ledger"
								name="names"
								
								placeholder="Enter Ledger"
								/>
								
								
							</div>
							
							<div class="col-md-6 fw-bold mt-3">
								<b-form-group
								id="fieldset-1"
								label="Amount"
								label-for="input-1"
								
								>
									<b-form-input id="input-1" type="number" trim placeholder="Enter Amount"  v-model="input.amount"></b-form-input>
								</b-form-group>
							</div>
							<span class="position-absolute right-0 cursor-pointer">
								<i class="fas fa-minus-circle" @click="remove(k)" v-show="k || ( !k && inputs.length > 1)"> Remove</i>
							</span>
						</div>
						<div class="d-flex align-items-end flex-column">
							<button class="btn btn-sm btn-venuecolor float-right"  id="popover-reactive-1" variant="primary" ref="button" @click="add()"><i class="mdi mdi-plus"></i> New</button>
						</div>
						<div class="row">	
							
							<div class="col-md-12 fw-bold mt-3">
								<textarea class="form-control  " v-model="desc" rows="5" placeholder="Enter Narration"></textarea>
							</div>
						</div>
						
					</b-modal>
        <!-- Modal -->
		
		<b-card class="mb-4 w-100 mt-2">
			<div class="venue-heading">
				<b-row>
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="3"
					md="5"
					sm="12"
					>
						
					</b-col>
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="2"
					md="4"
					sm="12"
					>
						<div class="w-100">
							
						</div>
					</b-col>
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="2"
					md="4"
					sm="12"
					>
						<div class="w-100">
							
						</div>
					</b-col> 
					
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="4"
					md="12"
					sm="12"
					>
						<div class=" d-flex justify-content-end button-text">
							<div class="p-1">
								<b-button class="venue-color" @click="openModal('Add',0)">
									Add Entries</b-button
								>
							</div>
							<div class="p-1">
								<b-button class="venue-color" @click="addLedger">
									Add Ledger</b-button
								>
							</div>
							
						</div>
                      
					</b-col>
				</b-row>
			</div>
			
			<div>
				<b-row>
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="7"
					md="12"
					sm="12"
					>
						<div
						class="
						w-100
						justify-content-sm-center
						justify-content-xl-start
						justify-content-md-start
						"
						>
							<div class="mt-2 venue-flex">
								<b-form-select
								style="max-width: 90px;"
								id="select_perpage"
								v-model="pageSize"
								:options="options"
								@change="handlePageSizeChange($event)"
								></b-form-select>
							
							</div>
						</div>
					</b-col>
					
					<b-col
					class="d-flex align-items-stretch"
					cols="12"
					lg="5"
					md="12"
					sm="12"
					>
						<div class="w-100 p-1">
							<b-form-input
							v-model="search"
							id="search"
							type="text"
							placeholder="Search here"
							v-on:keyup="key_search()"
							></b-form-input>
						</div>
					</b-col>
				</b-row>
			</div>
			<br />
			<div>
				<b-table
				:busy="load"
				:fields="fields"
				:items="expense"
				responsive
				hover
				bordered
				show-empty
				empty-text="No Data Available"
				class="text-center"
				>
					<template  #cell(total)="data">
						<span v-html="currency_check(data.item.total)"></span>
					</template>
					
					<template #cell(booking_id)="data">
						
                        <i class="fas fa-file fa-lg text-dark ml-2 cursor-pointer" @click="openModal('View',data.item.id)"></i>
								<i class="fas fa-edit fa-lg text-dark ml-2 cursor-pointer"  @click="openModal('Edit')"></i>


					</template>
				</b-table>
				<b-row align-v="center">
					<b-col cols="4">
						<span
						>Showing <b>{{ totalRows }}</b> out of
							<b>{{ totalData }}</b> entries.</span
						>
					</b-col>
					<b-col cols="8">
						<b-pagination
						v-model="page"
						:total-rows="count"
						:per-page="pageSize"
						@change="handlePageChange"
						prev-text="Prev"
						next-text="Next"
						align="right"
						></b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
	</div>
</template>

<script>
	import axios from "axios";
    import { validationMixin } from "vuelidate";
	import  helpers  from '../../../mixins/helpers';
	import {
		required,
		
	} from "vuelidate/lib/validators";
	export default {
        mixins: [validationMixin,helpers],
		name: "mybooking",
		data: () => ({
			title: "mybooking",
			
			fields: [
			{
				key: "date",
				label: "Date",
				sortable: true,
				tdClass: 'text-left',
				thClass: 'text-left',
			},
			{
				key: "receipt_no",
				label: "Receipt No",
				sortable: true,
				tdClass: 'text-left',
				thClass: 'text-left',
			},
			
			{
				key: "ledger_name",
				label: "Ledger",
				sortable: true,
				tdClass: 'text-left',
				thClass: 'text-left',
			},
		
			{
				key: "mode",
				label: "Mode",
				sortable: true,
				tdClass: 'text-right',
			},
            {
				key: "total",
				label: "Amount",
				sortable: true,
				tdClass: 'text-right',
			},
            {
				key: "booking_id",
				label: "Action",
			},
			],
			expense: [],
			options: [10, 20, 30, 50],
			
			pageSize: 10,
			show: false,
			load: true,
			filter: "",
			search: "",
			CurrentPage: 1,
			sortBy: "created_at",
			
			page: 1,
			count: 0,
			totalData: "",
			totalRows: "",
			
			PerPage: "50",
			ty: "Add",
			
			curncy: "",
            paymode:'',
			date:'',
			desc:'',
			inputs: [{
				name: '',
				amount: ''
			}],
			today:new Date().toLocaleString() ,
			input1: '',
			
			input2: '',
			input5: '',
			ledger_cat: '',
			input2state: null,
			options_: [],
			banks: [],
			options1: [{ text: '- Choose 1 -', value: '' }, 'Cash', 'Cheque', 'Online'],
			
			input2Return: '',
			popoverShow: false,
			select_ledger:[],
			income_list:[],
			receipt_no:'250',
		}),
        validations: {
			paymode: { required },
			date: { required },
			desc: { required },
			inputs: [{
				name: { required },
				amount:{ required }
			}]
			
		},
		components: {  },
		methods: {
			key_search() {
				const value = "1";
				this.handlePageChange(value);
			},
			handlePageChange(value) {
				this.page = value;
				this.load = true;
				this.bookingListing();
			},
			applyFilter() {
				const value = "1";
				this.handlePageChange(value);
			},
			getRequestParams(page, pageSize, sortBy = "created_at") {
				let params = {};
				if (page) {
					params["page"] = page - 1;
				}
				if (pageSize) {
					params["size"] = pageSize;
				}
				params["sortBy"] = sortBy;
				return params;
			},
           
			income_lists() {
				this.$store.commit("SET_BOOKING_DETAILS", 'block');
				this.$store.commit("SET_BOOKING_HEADER", 'Invoices/Payments');
				this.load = true;
				// this.venue = [];
				var keyword = this.search;
				const params = this.getRequestParams(
				this.page,
				this.pageSize,
				this.sortBy
				);
				axios
				.post("/api/income_list", {
					params,
					search_keyword: keyword,
                    cat:7
				})
				.then((resp) => {
					if (resp.data.status_code == 200) {
						this.expense = [];
						if (resp.data.status) {
							this.count = 0;
							this.totalRows = resp.data.ledger_list.length;
							
							for (var i = 0; i < resp.data.ledger_list.length; i++) {
								this.expense.push({
									date: this.date_format(resp.data.ledger_list[i].date),
									receipt_no: resp.data.ledger_list[i].receipt_no,
									ledger_name: resp.data.ledger_list[i].ledger_name,
									mode: resp.data.ledger_list[i].mode,
									id: resp.data.ledger_list[i].id,
									total:
									this.formatPrice(resp.data.ledger_list[i].total),
								});
							}
							this.show = false;
							this.load = false;
							} else {
							this.count = this.totalData = resp.data.total_records;
							this.totalRows = resp.data.ledger_list.length;
							
							this.show = false;
							this.load = false;
						}
					}
				});
			},
			formatPrice(value) {
				//let val = (value/1).toFixed(2).replace(',', '.')
				return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
			},
			handlePageSizeChange(event) {
				this.pageSize = event;
				this.load = true;
				this.page = 1;
				this.bookingListing();
			},
			
			
			export_pdf() {
				var keyword = this.search;
				const params = this.getRequestParams(
				this.page,
				this.pageSize,
				this.sortBy
				);
				axios
				.post(
				"/api/bookings_pdf_export",
				{
					params,
					search_keyword: keyword,
					from_date: this.start_date,
					to_date: this.end_date,
					booked_on: this.booked_on,
					venue_name: this.venue_name_value,
				},
				{
					responseType: "blob",
				}
				)
				.then((resp) => {
					const url = window.URL.createObjectURL(new Blob([resp.data]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", "booking_list.pdf");
					document.body.appendChild(link);
					link.click();
				});
			},
			
			newPrint(book_id)
			{
				book_id
				var printWindow = window.open('', '', 'height=400,width=800');
				
				printWindow.document.write('<html><head><title></title><link href="/css/finsoft-print.css" rel="stylesheet"><style type="text/css"> .table{ border-collapse: collapse;width:100%;}.table-bordered>:not(caption)>*>* { border-width: 0 1px;}.table>:not(caption)>*>* {padding: 0.5rem 0.5rem;}.table-bordered>:not(caption)>* { border-width: 1px 0;}tbody, td, th, thead, tr { border-style: solid; border-width: 0;}</style></head>');
				printWindow.document.write('<body class="background-image" style="padding:10px;"><div class="page-header " >');
				printWindow.document.write('<div class="row top-headers overlays">');
				printWindow.document.write('<div class="left-side" >');
				printWindow.document.write('<div style="color:#387a6c; font-family: cursive;font-size:16px">ddd</div>');
				printWindow.document.write('<div style="color:#387a6c; font-family: cursive;font-size:14px">C.R.: dddd</div>');
				printWindow.document.write('</div>');
				
				printWindow.document.write('<div class="logo-side" >');
				printWindow.document.write('<img src="../../assets/images/venue-logo.png" class="logo-booking" alt="Booking logo">');
				printWindow.document.write('</div>');
				
				printWindow.document.write('<div class="right-side">');
				
				printWindow.document.write('<div style="color:#387a6c; font-size:18px; margin-right:10px">مؤسسة القيادة العربية</div>');
				printWindow.document.write('<div style="color:#387a6c; font-size:14px ;margin-right:10px">للتشغيل والصيانة</div>');
				printWindow.document.write('<div style="color:#387a6c; font-size:14px ; margin-right:10px">C.R .: </div>');
				printWindow.document.write('</div>');
				printWindow.document.write('</div>');
				
				
				printWindow.document.write('</div>');
				
				printWindow.document.write('<div class="page-footer">');
				printWindow.document.write('<div class="dflex d-between">');
				printWindow.document.write('<div class="w-33" >');
				printWindow.document.write('<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pin-map mt-10" viewBox="0 0 16 16">');
				printWindow.document.write('<path fill-rule="evenodd" d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8l3-4z"/>');
				printWindow.document.write('<path fill-rule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z"/>');
				printWindow.document.write('</svg>	ddddd');
				printWindow.document.write('</div>');
				printWindow.document.write('<div class="w-33" >');
				printWindow.document.write('<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-at mt-10" viewBox="0 0 16 16">');
				printWindow.document.write('<path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z"/>');
				printWindow.document.write('<path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z"/>');
				printWindow.document.write('</svg>	ddddd ');
				printWindow.document.write('</div>');
				printWindow.document.write('<div class="w-33" >');
				printWindow.document.write('<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-diagram-2 mt-10" viewBox="0 0 16 16">');
				printWindow.document.write('<path fill-rule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H11a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 5 7h2.5V6A1.5 1.5 0 0 1 6 4.5v-1zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1zM3 11.5A1.5 1.5 0 0 1 4.5 10h1A1.5 1.5 0 0 1 7 11.5v1A1.5 1.5 0 0 1 5.5 14h-1A1.5 1.5 0 0 1 3 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1A1.5 1.5 0 0 1 9 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"/>');
				printWindow.document.write('</svg>	Branch :');
				printWindow.document.write('</div>');
				printWindow.document.write('</div>');
				printWindow.document.write('<div class="dflex d-between">');
				printWindow.document.write('<div class="w-33" >');
				printWindow.document.write('<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">');
				printWindow.document.write('<path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>');
				printWindow.document.write('</svg> 	ddddd ');
				printWindow.document.write('</div>');
				printWindow.document.write('<div class="w-33" >');
				printWindow.document.write('<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16">');
				printWindow.document.write('<path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/>');
				printWindow.document.write('</svg> 	 dddd');
				printWindow.document.write('</div> ');
				printWindow.document.write('<div class="w-33" >');
				printWindow.document.write('ddddd');
				printWindow.document.write('</div> ');
				printWindow.document.write('</div>'); 
				printWindow.document.write('</div>');
				printWindow.document.write('<div class="print-content">');
				printWindow.document.write('dddd');
				printWindow.document.write('</div></body></html>');
				printWindow.document.close();
				printWindow.onload = function () { printWindow.print(); }
				window.close();
			},
            validateState(name) {
				
				const { $dirty, $error } = this.$v[name];
				return $dirty ? !$error : null;
			},
		
			add () {
				this.inputs.push({
					name: '',
					amount: ''
				})
				console.log(this.inputs)
			},
			
			remove (index) {
				this.inputs.splice(index, 1)
			},
			
			onOk() {
				axios.post("/api/add_new_ledger",{ ledger:this.input2,name:this.ledger_cat}).then((resp) => {
					if (resp.data.status) {
						
                        this.$refs['open_ledger_list'].hide()
						this.ledger_list();
						
						this.$root.$refs.app.showToast(
						"success",
						resp.data.message,
						);
					}
					else {
						this.$root.$refs.app.showToast(
						"danger",
						resp.data.message,
						);
					}
					
				});
			},
		
			category_list()
			{
				axios.post("/api/get_ledger_category",{ cat:7 }).then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							for(var i=0;i<resp.data.ledger.length;i++)
							{
								this.options_.push({
									text: resp.data.ledger[i].name,
									value: resp.data.ledger[i].id,
								});
							}
							
						}
					}
				});
			},
			bank_list()
			{
				axios.get("/api/get_profile").then((resp) => {
					
					
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							this.input5 = resp.data.users.account_no;
							this.banks.push({
								text: resp.data.users.bank_name,
								value: resp.data.users.account_no,
							});
							
							
							
							
						}
					}
				});
			},
			ledger_list()
			{
                this.select_ledger=[];
				axios.post("/api/ledger_list",{ cat:7 }).then((resp) => {
					if (resp.data.status_code == 200) {
						if (resp.data.status) {
							for(var i=0;i<resp.data.ledger_list.length;i++)
							{
								this.select_ledger.push(
								{
									value:resp.data.ledger_list[i].id,
									text:resp.data.ledger_list[i].name
								}
								);
							}
						}
					}
				});
			},
			
			openModal(type,id)
			{
                if(id!=0)
                {
                    this.inputs =[];
                    axios.post("/api/expense_list_view",{ id:id }).then((resp) => {
                        if (resp.data.status_code == 200) {
						if (resp.data.status) {
                        this.receipt_no = resp.data.income_header.receipt_no;
                        this.date = resp.data.income_header.date;
                        this.desc = resp.data.income_header.description;
                        this.paymode = resp.data.income_header.mode;
                        for(var i= 0; i<resp.data.income_data.length;i++)
                        {
                            this.inputs.push(
                            {
                                name:resp.data.income_data[i].ledger_id,
                                amount:resp.data.income_data[i].amount,
                            })
                        }

                        }
                    }
                    })
                }
                else
                {
                    
                    this.receipt_no = '';
                        this.date = '';
                        this.desc = '';
                        this.paymode ='';
                    axios.post("/api/receipt_no").then((resp) => {
                        this.receipt_no = resp.data;
                    })
                }
				type
				this.$refs['open_booking_list'].show()
			},
			addLedger()
			{
				this.$refs['open_ledger_list'].show()
			},
			handleOks(bvModalEvent) {
				bvModalEvent.preventDefault();
				
				this.$v.$touch();
				if (this.$v.$anyError) {
					
					return;
					} else {
					
					this.handleSubmit();
				}
			},
			handleOk()
			{
				
				
				axios.post("/api/add_new_ledger_insert",{ 
					date:this.date,
					desc:this.desc,
					Ledger:this.inputs,
					paymode:this.paymode,
				accno:this.input5,
				cat:7,
			}).then((resp) => {
		if (resp.data.status) {
	
this.income_lists();
this.$refs['open_booking_list'].hide()
this.$root.$refs.app.showToast(
"success",
	resp.data.message,
		);
            }
				else {
				this.$root.$refs.app.showToast(
				"danger",
			resp.data.message,
		);
	}

});

},
currency_check(amt) {


return '<i class="mdi '+this.curncy+'"></i>'+amt;
	},
getProfile() {
axios.get("/api/get_profile").then((resp) => {
	if (resp.data.status_code == 200) {
	this.curncy= resp.data.users.currencys;
	}
	});
		},
		},
		mounted() {
			this.income_lists();
this.bank_list();
this.category_list();
this.getProfile();
	
	
	this.ledger_list();
			document.documentElement.scrollTop = 0;
		},
	};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
	#search {
	max-width: 280px;
	}
</style>