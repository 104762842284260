<template>
	<div>
		<b-tabs class="mt-3 ">
			
			
			<b-tab :active="url_name=='/income-expense'"  @click="$router.push('/finance/income-expense')"  v-if="permision_access[19].vb_view==1">
				<template #title>
					<strong>Entries</strong>
				</template>
				<transition name="fade-transform" mode="out-in">
					<router-view/>
				</transition>
				
			</b-tab>

			<b-tab :active="url_name=='/finance/income/vb-receipt'"  @click="$router.push('/finance/income/vb-receipt')"  >
				<template #title>
					<strong>VB Receipt</strong>
				</template>
				<transition name="fade-transform" mode="out-in">
					<router-view/>
				</transition>
				
			</b-tab>
			
			<!-- <b-tab :active="url_name=='/finance/taxation/income_entry'"  @click="$router.push('/finance/income-expense/income_entry')"  v-if="permision_access[19].vb_view==1">
				<template #title>
					<strong>Entries</strong>  
				</template>
				<transition name="fade-transform" mode="out-in">
					<router-view/>
				</transition>
			</b-tab>  -->
		</b-tabs>
	</div>
</template>

<script>
import axios from "axios";
	import { mapState } from "vuex";
	/* import baseprice from './baseprice.vue'
	import addOn from './addOn.vue'
	import security_deposit from './security_deposit.vue'
	import block from './block.vue'
	import income_entry from './income_entry.vue' */

	export default {
		
	data:() => ({
		permision_access:'',
		url_name:'',
	}),
  components:
  {
	/* baseprice,
	addOn,
	security_deposit,
	block,
	income_entry, */
  },
  methods:
  {
	access_permission()
	{
		axios
			.post("/api/access_permission_view").then((resp) => {
				this.permision_access = resp.data;
			});
	}
  },
  mounted()
  {
	this.access_permission();
	this.url_name = window.location.pathname;
  },
		
		computed: {
			...mapState(['set_income_expense_option']),
		},
	
	}
	
</script>

<style>
	.input__autocomplete input:first-child
	{
	border:none !important;
	}
	.right-0
	{
		right:10px;
		margin-top: 18px;
	}
</style>